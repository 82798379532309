import React from "react";

import CTASimple from "../components/CTASimple";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../components/layout";

// Styling.
import { css } from "@emotion/core";

const styles = {
  wrap: css`
    background-color: #f8fafc;
  `,
  buffer: css`
    margin-bottom: 110px;
  `,
};

const EducatorPage = () => (
  <Layout css={styles.wrap}>
    <Header />

    <CTASimple
      title="TED-Ed Idea Workshop"
      text="Looking for a way to motivate educators and to accelerate their engagement with the TED Masterclass course? Based on the first three lessons, the TED-Ed Idea Workshop is a 60-minute live virtual workshop designed to support educators with the development and sharing of ideas. Get in touch today to learn more."
      text2=""
      grayBG={false}
      whiteBG={true}
      cta={{
        label: "Get in touch",
        href: "https://airtable.com/shrSd9aRJoKaO1rd0",
        invert: false,
      }}
      cta2={false}
      ><p>Have Questions? Check out our <a href="https://masterclass-help.ted.com/">FAQs.</a></p></CTASimple>
    <Footer />
  </Layout>
);

export default EducatorPage;
