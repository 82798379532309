import React from "react";

import { blogMd, blogLg } from "../../styles/breakpoints";
import { Inter02 } from "../../styles/blog";
import { textSecondary, watermelon } from "../../styles/colors";

import { css } from "@emotion/core";
import tw from "tailwind.macro";

const styles = {
  wrap: css`
    ${tw`text-center`}
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    padding: 8px 25px 71px;

    @media (${blogMd}) {
      max-width: ${668 + 50}px;
      padding-bottom: 40px;
    }

    @media (${blogLg}) {
      max-width: ${800 + 50}px;
    }
  `,
  intro: css`
    ${Inter02};
    color: ${textSecondary};
    margin-bottom: 16px;

    @media (${blogMd}) {
      margin-bottom: 30px;
    }
  `,
  link: css`
    ${Inter02};
    color: ${textSecondary};
    text-decoration: underline;
    padding: 0;

    * + & {
      padding-left: 8px;
    }

    &:hover {
      color: ${watermelon};
      text-decoration: none;
    }

    @media (${blogMd}) {
      * + & {
        padding-left: 16px;
      }
    }
  `
};

const Footer = () => (
  <div css={styles.wrap}>
    <p css={styles.intro}>
      &copy; {new Date().getFullYear()} TED Conferences, LLC. All rights
      reserved. Please note that the TED Talks Usage policy does not apply to
      this content and is not subject to our creative commons license.
    </p>
    <p>
      <a
        css={styles.link}
        href="https://www.ted.com/about/our-organization/our-policies-terms/ted-com-terms-of-use"
        target="_blank"
        rel="noopener noreferrer"
        data-track-action="Footer Link"
      >
        Terms of Use
      </a>
      <a
        css={styles.link}
        href="https://www.ted.com/about/our-organization/our-policies-terms/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        data-track-action="Footer Link"
      >
        Privacy
      </a>
      <a
        css={styles.link}
        href="https://www.ted.com/contact"
        target="_blank"
        rel="noopener noreferrer"
        data-track-action="Footer Link"
      >
        Contact
      </a>
      <a
        css={styles.link}
        href="https://masterclass-app.ted.com/#/login"
        target="_blank"
        rel="noopener noreferrer"
        data-track-action="Footer Link"
      >
        Administrator login
      </a>
    </p>
  </div>
);

export default Footer;
