import React from "react";

import * as S from "./Typography.style";

const variants = {
  Inter6: "h1",
  Inter5: "h2",
  Inter4: "h3",
  Inter3: "h4",
  Inter2: "h5",
  Inter1: "p",
  "Inter-1": "p",
  "Inter-2": "p",
};

const Typography = ({ variant, ...props }) => (
  <S.Typography as={variants[variant]} variant={variant} {...props} />
);

export default Typography;
