import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { inter as fontWeights } from "../styles/font-weights";

export const Inter6 = css`
  font-size: 48px;
  line-height: ${56 / 48};
  letter-spacing: -1.8px;
`;

export const Inter5 = css`
  font-size: 36px;
  line-height: ${44 / 36};
  letter-spacing: -1.2px;
`;

export const Inter4 = css`
  font-size: 30px;
  line-height: ${40 / 30};
  letter-spacing: -1.15px;
`;

export const Inter3 = css`
  font-size: 24px;
  line-height: ${36 / 24};
  letter-spacing: -0.5px;
`;

export const Inter2 = css`
  font-size: 20px;
  line-height: ${30 / 20};
  letter-spacing: -0.5px;
`;

export const Inter1 = css`
  font-size: 18px;
  line-height: ${30 / 18};
  letter-spacing: -0.2px;
`;

export const Inter_n1 = css`
  font-size: 16px;
  line-height: ${26 / 16};
  letter-spacing: -0.5px;
`;

export const Inter_n2 = css`
  font-size: 14px;
  line-height: ${22 / 14};
  letter-spacing: -0.35px;
`;

export const weight = ({ weight }) => {
  if (fontWeights[weight]) {
    return css`
      font-weight: ${fontWeights[weight]};
    `;
  }
  return ``;
};

export const responsive = ({ size, variant }) => css`
  @media (${size}) {
    ${variants[variant]};
  }
`;

export const marginValue = (v) => (v === "auto" ? "auto" : `${v * 4}px`);

const variants = {
  Inter6,
  Inter5,
  Inter4,
  Inter3,
  Inter2,
  Inter1,
  "Inter-1": Inter_n1,
  "Inter-2": Inter_n2,
};

export const Typography = styled.div`
  ${({ variant }) => variants[variant] || ``};
  ${weight};
  ${({ align }) => align && `text-align: ${align};`};
  ${({ mt }) => mt && `margin-top: ${marginValue(mt)}`};
  ${({ mb }) => mb && `margin-bottom: ${marginValue(mb)}`};
  ${({ m }) => m && `margin-bottom: ${marginValue(m)}`};
  ${({ responsive: sizes }) => sizes && sizes.map((size) => responsive(size))};
`;
