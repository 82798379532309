import React from "react";

import Btn, { CenterBox } from "./Btn";
import Typography from "../components/Typography";

import tw from "tailwind.macro";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { md } from "../styles/breakpoints";

const styles = {
  wrap: css`
    ${tw`max-w-lg mx-auto`}
    margin-top: 16px;
    margin-bottom: 16px;

    @media (${md}) {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  `,
  footerWrap: css`
    ${tw`max-w-lg mx-auto`}
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;

    a {
      ${tw`text-black no-underline`}
    }
  `,
  grayBg: tw`py-10 bg-grey-lightest`,
  whiteBg: tw`py-10 bg-white`,
  headline: css`
    ${tw`mb-4 text-3xl xl:text-4xl font-bold text-center leading-normal tracking-tight`}
    line-height: ${40 / 30};
  `,
  title: tw`leading-normal mb-8 text-center text-xl font-normal tracking-tight`,
  text: css`
    margin-bottom: 54px;

    @media (${md}) {
      margin-bottom: 72px;
    }
  `,
  text2: css`
    margin-bottom: 24px;
  `,
  btn: css`
    width: 270px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.06),
      0px 4px 5px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.05);
  `,
  btn1: css`
    margin-bottom: 20px;

    @media (${md}) {
      margin-right: 24px;
      margin-bottom: 0;
    }
  `,
};

const S = {
  Wrap: styled.div`
    ${tw`px-4`};
    ${({ grayBG }) => grayBG && styles.grayBg};
    ${({ whiteBG }) => whiteBG && styles.whiteBg};
  `,
};

const CTAButtons = ({ cta, cta2 }) => (
  <>
    {cta && !cta2 && <Btn {...cta} center />}
    {cta && cta2 && (
      <CenterBox>
        <Btn css={[styles.btn, styles.btn1]} {...cta} />
        <Btn css={styles.btn} {...cta2} />
      </CenterBox>
    )}
  </>
);

const CTASingle = ({ title, cta, cta2 }) => (
  <>
    <Typography
      variant={"Inter-1"}
      responsive={[{ size: md, variant: "Inter1" }]}
      css={tw`mb-6 text-center`}
    >
      {title}
    </Typography>
    <CTAButtons cta={cta} cta2={cta2} />
  </>
);

const CTAMultiLine = ({ title, text, text2, cta, cta2 }) => (
  <>
    <h2 css={styles.headline}>{title}</h2>
    <p css={[styles.title, styles.text]}>{text}</p>
    {text2 && <p css={[styles.title, styles.text2]}>{text2}</p>}
    <CTAButtons css={tw`mt-12`} cta={cta} cta2={cta2} />
  </>
);

const CTASimple = ({ grayBG, whiteBG, ...props }) => {
  const { text, children } = props;

  return (
    <S.Wrap grayBG={grayBG} whiteBG={whiteBG}>
      <div css={styles.wrap}>
        {text ? <CTAMultiLine {...props} /> : <CTASingle {...props} />}
        {children && <div css={styles.footerWrap}>{children}</div>}
      </div>
    </S.Wrap>
  );
};

export default CTASimple;
